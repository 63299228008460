

















































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as cls from "@/tool/_class";
import * as TestPaperApi from "@/api/testPaper";
import * as _ from "lodash";
import GroupItem from "@/components/view-item/group-item.vue";

class TestPaperViewItem extends cls.TestPaper {
  selected: boolean = false;
}

class Params {
  multiple: boolean = true;
}

@Component({
  name: "TestPaperDialog",
  components: {
    GroupItem,
  },
})
export default class extends Vue {
  @Prop({ default: () => new Params() }) private params!: Params;
  @Emit("handleConfirmed") private handleConfirmed(
    selectList: TestPaperViewItem[],
    cancelList: TestPaperViewItem[]
  ) {}
  private query: {
    gradeNo: string;
    term02: string;
    term: string;
    keyWords: string;
    pageSize: number;
    curPage: number;
  } = {
    gradeNo: "",
    term02: "",
    term: "",
    keyWords: "",
    curPage: 1,
    pageSize: 999,
  };
  private selectedTestPaperIndex: number = 0;
  private authedTestPaperIds: string[] = [];
  private testPapers: TestPaperViewItem[] = [];
  private grades: cls.TestPaperGroupGradeOption[] = [];
  private term02s: {}[] = [
    { label: "上册", value: "上册" },
    { label: "下册", value: "下册" },
    { label: "全册", value: "全册" },
  ];
  private testPaperGroups: any[] = [];
  private async initTestPaperGroups(gradeNo: string, term02: string) {
    this.testPaperGroups.splice(0, this.testPaperGroups.length);
    this.query.term = "";
    const { data } = await TestPaperApi.getTestPaperGroupList({
      gradeNo,
      term02,
      curPage: 1,
      pageSize: 9999,
    });
    this.testPaperGroups = data.items;
  }
  private handleGradeNoChange(value: string) {
    this.initTestPaperGroups(this.query.gradeNo, this.query.term02);
  }
  private handleTerm02Change(value: string) {
    this.initTestPaperGroups(this.query.gradeNo, this.query.term02);
  }
  private loading: boolean = false;
  private async doGetTestPaperListClick() {
    try {
      this.loading = true;
      //
      if (this.query.gradeNo || this.query.term || this.query.term02) {
        //
      } else {
        this.$message({
          type: "warning",
          message: "请选择查询条件",
        });
        return;
      }
      //
      this.testPapers.splice(0, this.testPapers.length);
      const { data } = await TestPaperApi.getTestPaperList(this.query);
      data.items.forEach((item: cls.TestPaper) => {
        let testPaper = new TestPaperViewItem();
        _.merge(testPaper, item);
        testPaper.selected = this.authedTestPaperIds.some(
          (testPaperId: string) => testPaperId == testPaper.testPaperId
        );
        this.testPapers.push(testPaper);
      });
    } finally {
      this.loading = false;
    }
  }
  private unknownSelected(): boolean {
    return !this.testPapers.some((item) => {
      return item.selected;
    });
  }
  private unknownCanceled(): boolean {
    return !this.testPapers.some((item) => {
      return (
        !item.selected &&
        this.authedTestPaperIds.some(
          (testPaperId) => testPaperId == item.testPaperId
        )
      );
    });
  }
  private doSelectAllTestPaperClick() {
    this.testPapers.forEach((item) => {
      item.selected = true;
    });
  }
  private doCancelSelectAllTestPaperClick() {
    this.testPapers.forEach((item) => {
      item.selected = false;
    });
  }
  private doTestPaperClick(item: TestPaperViewItem, index: number) {
    item.selected = !item.selected;
    if (!this.params.multiple) {
      this.testPapers
        .filter((testPaper) => {
          return testPaper.testPaperId !== item.testPaperId;
        })
        .forEach((testPaper) => {
          testPaper.selected = false;
        });
    }
  }
  private async __init() {
    this.grades.splice(0, this.grades.length);
    const { data } = await TestPaperApi.getTestPaperGroupGradeOptionList();
    data.items.forEach((element: cls.TestPaperGroupGradeOption) => {
      this.grades.push(element);
    });
  }

  private dialogVisible: boolean = false;
  async show(
    params: { gradeNo?: string; term02?: string; term?: string },
    authedTestPaperIds: string[]
  ) {
    this.authedTestPaperIds.splice(0, this.authedTestPaperIds.length);
    this.authedTestPaperIds.push(...authedTestPaperIds);
    //
    _.merge(this.query, params);
    await this.__init();
    this.testPapers.splice(0, this.testPapers.length);
    if (params?.gradeNo || params?.term || params?.term02) {
      await this.doGetTestPaperListClick();
    }
    this.dialogVisible = true;
  }
  private async doOkClick() {
    let selectList: TestPaperViewItem[] = this.testPapers.filter(
      (testPaper) => {
        return testPaper.selected;
      }
    );
    let cancelList: TestPaperViewItem[] = this.testPapers.filter(
      (testPaper) => {
        return (
          !testPaper.selected &&
          this.authedTestPaperIds.some(
            (testPaperId) => testPaper.testPaperId == testPaperId
          )
        );
      }
    );
    this.handleConfirmed(selectList, cancelList);
    this.dialogVisible = false;
  }
}
